

import { defineComponent, inject, Ref, ref, watchEffect } from 'vue';
import { formatDate2 } from '@/utils/format';

// 返回 类似 2020-01-01 格式的字符串
const useFormatDateEffect = (year: number|string, month: number|string, day: number|string ) => {
  month < 10 && (month = '0' + month)
  day < 10 && (day = '0' + day)
  const date = year + '-' + month + '-' + day
  return date
}

/**
 * 日历初始化
 * @param value
 */
const useInitDateEffect = (currentDay: Ref<number>, currentYear: Ref<number>,
                           currentMonth: Ref<number>, currentWeek: Ref<number>,
                           arrDate: number[] , cur?: string) => {

  const date = cur? new Date(cur) : useSetDateEffect()
  currentDay.value = date.getDate()
  currentYear.value = date.getFullYear()
  currentMonth.value = date.getMonth() + 1
  currentWeek.value = date.getDay()

  // 组装日期 比如：2020-01-01
  const dateStr = useFormatDateEffect(currentYear.value, currentMonth.value, currentDay.value)
  const days = useFillDateEffect(arrDate, dateStr, currentWeek.value)
  return { days }
}

/**
 * 设置时间参数
 * @returns { Date }
 */
const useSetDateEffect = () => {
  const now = new Date()
  const d = new Date(useFormatDateEffect(now.getFullYear(), now.getMonth() + 1, 1))
  // 设置本月显示最多天数，上下月不会出现两行
  // d.setDate(35)
  return new Date(useFormatDateEffect(d.getFullYear(), d.getMonth()+1, 1))
}

/**
 * 填充日历数据
 * @param arrDate 签到日期数组
 * @param dateStr 初始化日期
 */
const useFillDateEffect = (arrDate: number[], dateStr: string, currentWeek: number) => {
  const days = ref<any>([])
  // 如果今天是周日，放在第一行第7个位置，前面6个 这里默认显示一周，
  // 如果需要显示一个月，则第二个循环为 i<= 35- this.currentWeek
  for (let i = currentWeek; i > 0; i--) {
    const d = new Date(dateStr)
    d.setDate(d.getDate() - i)
    // 用一个对象包装Date对象  以便为以后预定功能添加属性
    const dayObject: any = {}
    dayObject['day'] = d
    // 将日期放入data 中的days数组 供页面渲染使用
    days.value.push(dayObject)
  }
  // 其他周
  // 设置天数为35天,周日设置在第一位，循环从36开始
  for (let j = 0; j <= 36 - currentWeek; j++) {
    const d = new Date(dateStr)
    d.setDate(d.getDate() + j)
    const dddd = d.getDate()
    const dayObject = { day: d, isSign: useVerDateEffect(arrDate, dddd), isToday: useTodayEffect(d) }
    days.value.push(dayObject)
  }
  return days
}

/**
 * 判断是否签到
 * @param arrDate 签到日期数组
 * @param verDate 当前日期
 * @returns { boolean }
 */
const useVerDateEffect = (arrDate: number[], verDate: number) => {
  return arrDate.includes(verDate)
}

/**
 * 判断该日期是否是当前日期
 * @param date
 * @returns { boolean }
 */
const useTodayEffect = (date: Date) => {
  const currentDate = new Date()
  // 循环days的年月日
  const dataNyr = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`
  // 当前年月日
  const currentDateNyr = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}`
  return  dataNyr === currentDateNyr? true: false
}

/**
 * 上一月
 * @param 签到日期数组
 * @param year
 * @param month
 */
const usePickPreEffect = (arrDate: Array<number>, currentYear: string, currentMonth: string) => {
  const d = new Date(useFormatDateEffect(currentYear, currentMonth, 1))
  d.setDate(0)
  const dateFormat = useFormatDateEffect(d.getFullYear(), d.getMonth() + 1, 1)
  return dateFormat
}

/**
 * 下一月
 * @param 签到日期数组
 * @param year
 * @param month
 */
const usePickNextEffect = (arrDate: Array<number>, currentYear: string, currentMonth: string) => {
  const d = new Date(useFormatDateEffect(currentYear, currentMonth, 1))
  d.setDate(35)
  const dateFormat = useFormatDateEffect(d.getFullYear(), d.getMonth() + 1, 1)
  return dateFormat
}

export default defineComponent({
  name: 'calendar',
  props: {
    downFlag: {
      type: Boolean
    },
    // arrDate: {
    //   type: Array as PropType<number[]>,
    //   default: []
    // },
  },
  inject: ['changeMonth'],
  setup(props) {
    // 今日日期 几号
    const currentDay = ref(1)
    // 当前年份
    const currentYear = ref(1970)
    // 当前月份
    const currentMonth = ref(1)
    // 0,1...6 星期
    const currentWeek = ref(1)

    let arrDate: Ref<number[]> = inject<any>('arrDate');
    let result = useInitDateEffect( currentDay, currentYear, currentMonth, currentWeek, arrDate.value)
    let days = result.days
    const changeMonth = inject<Function>('changeMonth')!;
    const changeDate = inject<Function>('changeDate')!;

    const selectedDay = ref<string>('')

    let date = ''
    // 上一个月/下一个月切换
    const handleClick = (tag: string, year: string, month: string) => {
      if(tag === 'pre') {
        date = usePickPreEffect(arrDate.value, year, month)
      } else if (tag === 'next') {
        date = usePickNextEffect(arrDate.value, year, month)
      }
      changeMonth(date)
      result = useInitDateEffect( currentDay, currentYear, currentMonth, currentWeek, arrDate.value, date)
      days.value = []
      days.value = result.days.value
    }

    watchEffect( () => {
      result = useInitDateEffect( currentDay, currentYear, currentMonth, currentWeek, arrDate.value, date)
      days.value = []
      days.value = result.days.value
    })

    const qryDaily = (day: any) => {
      selectedDay.value = day.day.getDate()
      const date4day = formatDate2(day.day)
      changeDate({ 'date4day': date4day })
    }

    return {
      handleClick,
      days,
      currentMonth,
      currentYear,
      qryDaily,
      selectedDay
    }
  }
})
